import type {
  IAppRequest,
  IBasePaginationParams,
  IBasePaginationResponse,
  TCurrencyType,
  TPossibleNull,
} from '~/types/Shared.types';
import type { Colors } from '~/constants/colors.constants';
import type { TDropItemType } from '~/types/cases/dropItem';

export type TRarityKey = keyof typeof Colors.RARITY;

export enum EGroupSortParams {
  ACTIVE = 'active',
  ALL = 'all',
  WITHDRAWAL = 'withdrawal',
}

export type TGetInventoryRequestDTO = IBasePaginationParams & {
  group?: EGroupSortParams;
  userId?: number;
};

export type TGetInventoryResponseDTO = IAppRequest<IResponseInventoryDTO>;

export interface IResponseInventoryDTO extends IBasePaginationResponse<IInventory> {
  totalPrice: string;
}

export interface IInventorySendItemResponseDTO {
  code: number;
  msg: string;
  status: boolean;
  untilSending?: number;
}

export type TInventorySendItemResponseDTO = IAppRequest<IInventorySendItemResponseDTO>;

export interface IReplaceInventoryItemRequestDTO {
  inventoryId: number;
  replacementId: number;
}

export interface IReplaceInventoryItemResponseDTO {
  msg: string;
  status: boolean;
}

export type TReplaceInventoryItemResponseDTO = IAppRequest<IReplaceInventoryItemResponseDTO>;

export interface IInventory {
  from?: {
    battleUlid: TPossibleNull<number>;
    case: TPossibleNull<string>;
    from: EInventoryItemSource; // Поле с информацией об источнике предмета.
  };
  currency: TCurrencyType;
  id: number;
  img: string;
  isSelected?: boolean;
  isUltraRare?: boolean; // Является ли предмет Ультра-редким
  name: string;
  price: string;
  rare: TRarityKey;
  status: EInventoryItemStatus;
  takeAvailable?: boolean;
  timerData?: number;
  // Состояние в котором находится предмет, необходим для флоу продажи/замены/вывода итд.
  type?: TDropItemType; // Тип предмета (Флаг для распознавания type3)
  replacementItems?: IInventory[];
  updateAt: string;
}

export enum EInventoryItemSource {
  BATTLES = 'Battle',
  CASES = 'Case',
  CONTRACTS = 'Contract',
  EXCHANGE = 'Exchange',
  UPGRADE = 'Upgrade',
}

export enum EInventoryItemStatus {
  ACCEPTED = 'accepted', // вещь выведена на стим или баланс стима
  AUTOSELLED = 'autoselled', // вещь продана
  BAG = 'bag', // вещь забрана из-за абуза
  BUG = 'bug', // вещь забрана из-за абуза
  BEFORE = 'before', // статус вещи, после выбора предмета в pick_items
  CANCELED = 'canceled',
  CHANGED = 'changed', // вещь заменена при неудачной попытке вывода
  PROGRESS = 'progress', // вещь получена при дропе
  ERROR = 'error', // ошибка
  NO_LINK = 'no_link', // Некорректная ссылка
  SEND = 'send', // вещь отправлена, по пока не передана на стим
  SUPPORT = 'support', // вещь передана поддержкой, то же самое что progress
  SOLD = 'selled', // вещь продана
  UNPAID = 'unpaid', // вещь получена в кейс батлах, но недоступна, т.к. юзер не победил.
  CONFIRM_STEAM = 'CONFIRM_STEAM',
  DEFAULT = 'default',
  EXCHANGED = 'EXCHANGED',
  EXCHANGING = 'EXCHANGING',
  PREPARING = 'PREPARING',
  STEAM_WITHDRAWN = 'STEAM_WITHDRAWN',
  UPGRADED = 'to_upgrade', // вещь обменена по апгрейду
  CONTRACTED = 'to_contract', // вещь обменена по контракту
  VENDOR_WAITING = 'vendor waiting',
  COMPLETED = 'completed',
}
